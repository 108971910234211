<div id="columns">
  <div></div>
  <div id="rows">
    <div></div>
    <div style="text-align:center;">
      <artbox [shape]="'logo_pinkforestprojects'" class="easein-quick"></artbox>
      <label class="easein-slow">{{this.label}}</label>
    </div>
    <div>
      <usebas-scrollbutton></usebas-scrollbutton>
    </div>
  </div>
  <div></div>
</div>
