import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-story-infinitewhy',
  templateUrl: './story-infinitewhy.component.html',
  styleUrls: ['./story-infinitewhy.component.css']
})
export class StoryInfinitewhyComponent implements OnInit {

  @Input() heading: string
  @Input() text_left: string
  @Input() text_right: string

  constructor() {}

  ngOnInit(): void {}

}
