import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-story-testimonial',
  templateUrl: './story-testimonial.component.html',
  styleUrls: ['./story-testimonial.component.css']
})
export class StoryTestimonialComponent implements OnInit {

  @Input() goodneighbourprogram_heading: string;
  @Input() goodneighbourprogram_text: string;
  @Input() testimonial_heading: string;
  @Input() testimonials: any;

  constructor() {}

  ngOnInit(): void {}

}
