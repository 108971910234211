<div></div>
<div id="rows">
  <div></div>
  <div id="social">
    <label>Follow us</label>
    <div>
        <span><a href="http://instagram.com/pinkforestprojects" target="_blank"><artbox id="instagram" [shape]="'logo_instagram'"></artbox></a></span>
        <span><a href="http://facebook.com/pinkforestprojects" target="_blank"><artbox id="facebook" [shape]="'logo_facebook'"></artbox></a></span>
        <span>@pinkforestprojects</span>
    </div>
  </div>
  <div>
    <div id="textbox">
        <div class="disclaimer" [innerHtml]=this.disclaimer></div>
        <div></div>
    </div>
  </div>
  <div id="floor">
    <app-navbar></app-navbar>
    <div class="text-right">
        <span>647 406 4138</span>
        <a href="mailto:info@pinkforestprojects.com" class="decor">info@pinkforestprojects.com</a>
    </div>
    <div>
      <artbox id="logo" [shape]="'logo_pinkforestprojects'"></artbox>
    </div>
  </div>
  <div></div>
</div>
<div></div>
