import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-story-identity',
  templateUrl: './story-identity.component.html',
  styleUrls: ['./story-identity.component.css']
})

export class StoryIdentityComponent implements OnInit {

  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}
}
