import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-story-discover',
  templateUrl: './story-discover.component.html',
  styleUrls: ['./story-discover.component.css']
})
export class StoryDiscoverComponent implements OnInit {

  @Input() heading: string;
  @Input() text_left: string
  @Input() text_right: string

  constructor() {}

  ngOnInit(): void {}

}
