import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-story-infinitehow',
  templateUrl: './story-infinitehow.component.html',
  styleUrls: ['./story-infinitehow.component.css'],
})

export class StoryInfinitehowComponent implements OnInit {

  @Input() heading: string;
  @Input() text: string;
  @Input() services: Array<any>;

  constructor() {}

  ngOnInit(): void {}

}
