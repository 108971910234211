import { Component, OnInit } from '@angular/core';
import { ContentbasService } from 'contentbas';
import { ArtboxService } from 'artbox';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  // identity
  public identity_label: string;

  // discover
  public discover_heading: string;
  public discover_text_left: string;
  public discover_text_right: string;

  // infinite why
  public infinitewhy_heading: string;
  public infinitewhy_text_left: string;
  public infinitewhy_text_right: any;

  // infinite who
  public infinitewho_heading: string;
  public infinitewho_text: string;
  public infinitewho_profiles: any;

  // infinite you
  public infiniteyou_heading: string;
  public infiniteyou_text: string;

  // infinite how
  public infinitehow_heading: string;
  public infinitehow_text: string;
  public infinitehow_services: any;

  // infinite interest
  public infiniteinterest_heading: string;
  public infiniteinterest_text: string;
  public infiniteinterest_articles: any;

  // testimonial
  public testimonial_heading: string;
  public testimonial_text: string;
  public testimonial_testimonials: any;

  // good neighbour program
  public goodneighbourprogram_heading: string;
  public goodneighbourprogram_text: string;

  // disclaimer
  public disclaimer: string;

  constructor(private contentbas: ContentbasService, private artbox: ArtboxService) {
    this.contentbas.CLIENT = environment.CONTENTBAS_CLIENT;
    this.artbox.CLIENT = environment.ARTBOX_CLIENT;
  }

  ngOnInit(): void {
    // reduce api calls by getting objects, then filtering by id
    this.contentbas.getLabels().then(objs => {
      this.identity_label = objs.filter(obj => obj.id == '43R33Fla6cq5BjxksF0lkj')[0].label;
      this.discover_heading = objs.filter(obj => obj.id == 'fVkShxIs5yJihG6xwAxvy')[0].label;
      this.infinitehow_heading = objs.filter(obj => obj.id == '5DKG5PTPJyiiUdHHmA0UuW')[0].label;
      this.infinitewhy_heading = objs.filter(obj => obj.id == '26M1awzBOpmmJdWPz05xaw')[0].label;
      this.infinitewho_heading = objs.filter(obj => obj.id == 'XDOXRQvY8Qoq9R72OOPUv')[0].label;
      this.infiniteyou_heading = objs.filter(obj => obj.id == '6QTy5hsFxnZ4I2fznOg496')[0].label;
      this.infiniteinterest_heading = objs.filter(obj => obj.id == '2H92G4ROfKrtX35JyWhdUW')[0].label;
      this.testimonial_heading = objs.filter(obj => obj.id == 'cdRUAQ7hUId0BpwImrWLf')[0].label;
      this.goodneighbourprogram_heading = objs.filter(obj => obj.id == '2wOk2pcjmU4b4SteSzxtpI')[0].label;
    });

    this.contentbas.getMultiLabels().then(objs => {
      this.discover_text_left = objs.filter(obj => obj.id == 'al6Qj640y6sqVRdeudxAv')[0].formattedLabel;
      this.discover_text_right = objs.filter(obj => obj.id == '6t9nqiBbrwHo1dIqoxzDLe')[0].formattedLabel;
      this.infinitehow_text = objs.filter(obj => obj.id == '6t9nqiBbrwHo1dIqoxzDLe')[0].formattedLabel;
      this.infinitewhy_text_left = objs.filter(obj => obj.id == '3JDJ4QbB9JLyRSuYBbf7Tp')[0].formattedLabel;
      this.infinitewhy_text_right = objs.filter(obj => obj.id == '5UygQhqgYAhM3FiVhFd6HS')[0].formattedLabel;
      this.infinitewho_text = objs.filter(obj => obj.id == '3WzwIgL8aGK0juHgVCpfBJ')[0].formattedLabel;
      this.infiniteyou_text = objs.filter(obj => obj.id == '73TLeyOTFqJsS05wsZWDMo')[0].formattedLabel;
      this.infiniteinterest_text = objs.filter(obj => obj.id == '4l3WidpftI67lkMiE7t9Vs')[0].formattedLabel;
      this.infiniteinterest_articles = objs.filter(obj => obj.tags != undefined && obj.tags.includes('article'));
      this.goodneighbourprogram_text = objs.filter(obj => obj.id == '5XIA0S5FDatWN8JVpk7Naz')[0].formattedLabel;
      this.testimonial_text = objs.filter(obj => obj.id == '5XIA0S5FDatWN8JVpk7Naz')[0].formattedLabel;
      this.testimonial_testimonials = objs.filter(obj => obj.tags != undefined && obj.tags.includes('testimonial'));
      this.disclaimer = objs.filter(obj => obj.id == '4P7MiT7YtFXfBaOo5M1aiS')[0].formattedLabel;
    });

    this.contentbas.getServices().then(objs => {
      // sort by title
      this.infinitehow_services = objs.sort((a, b) => (a.title > b.title) ? 1 : -1);
    });

    this.contentbas.getProfiles().then(objs => {
      this.infinitewho_profiles = objs.sort((a, b) => (a.firstName < b.firstName) ? 1 : -1);
    });
  }
}
