import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { TempComponent } from './temp/temp.component';
import { HomeComponent } from './home/home.component';
import { StoryInfinitehowComponent } from './story-infinitehow/story-infinitehow.component';

const routes: Routes = [
  { path: '', component: TempComponent },
  { path: 'dev',  component: HomeComponent },
  { path: 'how', component: StoryInfinitehowComponent },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
