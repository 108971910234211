import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-story-infiniteinterest',
  templateUrl: './story-infiniteinterest.component.html',
  styleUrls: ['./story-infiniteinterest.component.css']
})
export class StoryInfiniteinterestComponent implements OnInit {

  @Input() heading:string;
  @Input() text:string;
  @Input() articles:Array<any>;

  constructor() {}

  ngOnInit(): void {}

}
