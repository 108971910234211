<div id="columns">
  <div></div>
  <div id="rows">
    <div></div>
    <div>
      <div id="title">
          <label class="app-story-title">{{this.heading}}</label>
      </div>
      <div id="textbox">
          <div class="app-story-text" [innerHtml]=this.text_left></div>
          <div></div>
          <div class="app-story-text" [innerHtml]=this.text_right></div>
      </div>
    </div>
    <div id="lotus" style="text-align: center;">
      <artbox [shape]="'graphic_lotus_flower'"></artbox>
    </div>
    <div></div>
  </div>
  <div></div>
</div>
