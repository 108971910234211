import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-story-infiniteyou',
  templateUrl: './story-infiniteyou.component.html',
  styleUrls: ['./story-infiniteyou.component.css'],
})
export class StoryInfiniteyouComponent implements OnInit {

  @Input() heading:string;
  @Input() text:string;

  constructor() {}

  ngOnInit(): void {}

}
