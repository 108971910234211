import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-story-infinitewho',
  templateUrl: './story-infinitewho.component.html',
  styleUrls: ['./story-infinitewho.component.css']
})
export class StoryInfinitewhoComponent implements OnInit {

  @Input() heading:string;
  @Input() text:string;
  @Input() profiles:Array<any>;

  constructor() {};

  ngOnInit(): void {};

}
