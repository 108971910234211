<div id="columns">
  <div></div>
  <div id="rows">
    <div></div>
    <div style="text-align:center;">
      <artbox [shape]="'logo_pinkforestprojects'" class="easein-quick"></artbox>
      <label class="easein-slow">Coming Soon...</label>
    </div>
    <div></div>
  </div>
  <div></div>
</div>
