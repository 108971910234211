<header>
  <div></div>
  <div><app-navbar></app-navbar></div>
  <div></div>
</header>

<app-story-identity
  [label]=this.identity_label>
</app-story-identity>

<app-story-discover
  [heading]=this.discover_heading
  [text_left]=this.discover_text_left
  [text_right]=this.discover_text_right>
</app-story-discover>

<app-story-infiniteyou
  [heading]=this.infiniteyou_heading
  [text]=this.infiniteyou_text>
</app-story-infiniteyou>

<app-story-infinitewho
  [attr.id]="'who'"
  [heading]=this.infinitewho_heading
  [text]=this.infinitewho_text
  [profiles]=this.infinitewho_profiles>
</app-story-infinitewho>

<app-story-infinitewhy
  [attr.id]="'why'"
  [heading]=this.infinitewhy_heading
  [text_left]=this.infinitewhy_text_left
  [text_right]=this.infinitewhy_text_right>
</app-story-infinitewhy>

<app-story-infinitehow
  [attr.id]="'how'"
  [heading]=this.infinitehow_heading
  [text]=this.infinitehow_text
  [services]=this.infinitehow_services>
</app-story-infinitehow>

<app-story-testimonial
  [goodneighbourprogram_heading]=this.goodneighbourprogram_heading
  [goodneighbourprogram_text]=this.goodneighbourprogram_text
  [testimonial_heading]=this.testimonial_heading
  [testimonials]=this.testimonial_testimonials>
</app-story-testimonial>

<app-story-infiniteinterest
  [heading]=this.infiniteinterest_heading
  [text]=this.infiniteinterest_text
  [articles]=this.infiniteinterest_articles>
</app-story-infiniteinterest>

<footer>
  <app-footer [disclaimer]=this.disclaimer></app-footer>
</footer>
