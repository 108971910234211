<div id="columns">
  <div></div>
  <div id="rows">
    <div></div>
    <div>
      <div id="title">
        <label class="app-story-title">{{this.heading}}</label>
      </div>
      <div class="app-story-text" [innerHtml]=this.text></div>
      <div>
        <label class="app-story-title subtitle decor">Meet Our Practitioners&hellip;</label><br />
        <div class="practitioners">
          <div class="practitioner" *ngFor="let profile of this.profiles">
            <usebas-imagemask [imageUrl]=profile.image [shape]="'circle'"></usebas-imagemask>
            <div>
              <label class="name app-story-title subtitle">{{profile.firstName}} {{profile.lastName}}</label>
              <label class="title">{{profile.title}}</label>
              <p class="bio app-paragraph-small">{{profile.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
  <div></div>
</div>
