// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  CONTENTBAS_CLIENT: {
    contentbas_access_token: "pinkforestprojects-L5fAG6rzfZHx7SjvTvGT",
    contentful_access_token: "1DqJSBYUqX9JsFz5TcUrCKEJCyovrEK87S56-JpqPcc",
    contentful_space_id: "70kb1h4yeios"
  },
  ARTBOX_CLIENT: {
    artbox_access_token: "development-rock-plant-karate"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
