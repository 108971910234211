import { Component, OnInit } from '@angular/core';
import { ArtboxService } from 'artbox';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-temp',
  templateUrl: './temp.component.html',
  styleUrls: ['./temp.component.css']
})
export class TempComponent implements OnInit {

  constructor(private artbox: ArtboxService) { }

  ngOnInit(): void {
    this.artbox.CLIENT = environment.ARTBOX_CLIENT;
  }

}
