
<artbox [shape]="'graphic_kundalini_hand'"></artbox>

<div id="columns">
    <div></div>
    <div id="rows">
      <svg viewBox="0 0 1000 10">
          <line x1="0" x2="1000" y1="3" y2="3" [attr.stroke-width]=3 stroke-linecap="round" [attr.stroke-dasharray]=3 />
      </svg>

      <div id="inner">
        <div class="content">
          <div>
            <label class="app-story-title">{{this.goodneighbourprogram_heading}}</label>
            <div class="app-story-text" [innerHtml]=this.goodneighbourprogram_text></div>
          </div>
          <div></div>
        </div>
        <div></div>
        <div class="content">
          <div>
            <label class="app-story-title">{{this.testimonial_heading}}</label>
            <div class="testimonials">
              <div class="testimonial" *ngFor="let testimonial of this.testimonials">
                <label><b>{{testimonial.label}}</b></label>
                <usebas-textbox [text]=testimonial.formattedLabel [preview]=testimonial.subLabel></usebas-textbox>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div></div>
    </div>
    <div></div>
</div>
