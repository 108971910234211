import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';


// Components
import { AppComponent } from './app.component';
import { TempComponent } from './temp/temp.component';
import { HomeComponent } from './home/home.component';

import { StoryDiscoverComponent } from 'src/app/story-discover/story-discover.component';
import { StoryIdentityComponent } from 'src/app/story-identity/story-identity.component';
import { StoryInfinitehowComponent } from 'src/app/story-infinitehow/story-infinitehow.component';
import { StoryInfiniteinterestComponent } from 'src/app/story-infiniteinterest/story-infiniteinterest.component';
import { StoryInfinitewhoComponent } from 'src/app/story-infinitewho/story-infinitewho.component';
import { StoryInfinitewhyComponent } from 'src/app/story-infinitewhy/story-infinitewhy.component';
import { StoryInfiniteyouComponent } from 'src/app/story-infiniteyou/story-infiniteyou.component';
import { StoryTestimonialComponent } from 'src/app/story-testimonial/story-testimonial.component';

import { NavbarComponent } from 'src/app/navbar/navbar.component';
import { FooterComponent } from 'src/app/footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    TempComponent,
    HomeComponent,
    StoryDiscoverComponent,
    StoryIdentityComponent,
    StoryInfinitehowComponent,
    StoryInfiniteinterestComponent,
    StoryInfinitewhoComponent,
    StoryInfinitewhyComponent,
    StoryInfiniteyouComponent,
    StoryTestimonialComponent,
    NavbarComponent,
    FooterComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
