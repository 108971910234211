import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtboxModule } from 'artbox';
import { UsebasModule } from 'usebas';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    exports: [
        ArtboxModule,
        CommonModule,
        UsebasModule,
    ],
  })
  export class SharedModule { }
