<div id="columns">
  <div></div>
  <div id="rows">
    <div></div>
    <div>
      <div id="title">
          <label class="app-story-title">{{this.heading}}</label>
      </div>
      <div id="inner">
          <div class="app-story-text" [innerHtml]=this.text></div>
          <!--
            <div></div>
            <div>
                <div class="article" *ngFor="let article of this.articles">
                  <label class="title-medium">{{article.title}}</label>
                  <label class="body-medium">{{article.brief}}</label>
                  <a href="{{article.button_link}}" target="_blank">Read More</a>
                </div>
                <div style="text-align: center;"><button class="app-story-button borderless" routerLink="/interest">View All</button></div>
            </div>
          -->
      </div>
    </div>
    <div></div>
  </div>
  <div></div>
</div>
<artbox [shape]="'graphic_swirl_pyramid'"></artbox>
