<div id="columns">
  <div></div>
  <div id="rows">
    <div></div>
    <div>
      <div id="title">
        <label class="app-story-title">{{this.heading}}</label>
      </div>
      <div class="grid">
        <div class="grid-item" *ngFor="let service of this.services">
          <div>
            <usebas-imagemask [imageUrl]=service.image [shape]="'heart'"></usebas-imagemask>
          </div>
          <div>
            <label class="app-story-title subtitle">{{service.title}}</label>
            <usebas-textbox [text]=service.description [preview]=service.preview [limit]=30></usebas-textbox>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
  <div></div>
</div>
